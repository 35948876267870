import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { PAYMENT_STATUS_ROUTE, PRE_RESERVATION_ROUTE, TICKET_TYPE_ROUTE } from "src/core/routing";
import { PagePurchase } from "../PagePurchase";
import { PageSuccessfulResult } from "../PageSuccessfulResult";
import { PageDefaltPayment } from "../PageDefaltPayment";
import { PageWaitingForResult } from "../PageWaitingForResult";
import { PageErrorResult } from "../PageErrorResult";
import { PageReservarionOnlyResult } from "../PageReservarionOnlyResult";
import { PageTicketType } from "../PageTicketType";
import React from "react";

export const MainPage: React.FC = () => {
  return (
    <Box>
      <Routes>
        <Route path={TICKET_TYPE_ROUTE} element={<PageTicketType />} />
        <Route path={PRE_RESERVATION_ROUTE} element={<PagePurchase />} />
        {/* <Route path={PAYMENT_STATUS_ROUTE} element={<PageDefaltPayment />}>
          <Route path=":reservationId" element={<PageWaitingForResult />} />
          <Route path=":reservationId" element={<PageErrorResult />} />
          <Route path=":reservationId" element={<PageSuccessfulResult />} />
          <Route path=":reservationId" element={<PageReservarionOnlyResult />} />
        </Route> */}
      </Routes>
    </Box>
  );
};
