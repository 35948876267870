import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import logo from "../../../assets/logo_jakub_pos.svg";
import { styles } from "./styles";
import { CheckOutStep, Tab } from "src/core/types";
import { clearFormValues, useFormValuesContext } from "src/core/context";
import { format } from "date-fns";
import styless from "./styles.module.css";
import check from "../../../assets/check (1).svg";
import print from "../../../assets/printing.svg";
import checkMark from "../../../assets/checkmark_big_bily.svg";
import checkMark2 from "../../../assets/checkmark_big_bily (2).svg";
import { useNavigate } from "react-router-dom";
import { TICKET_TYPE_ROUTE } from "src/core/routing";
import { usePrintTickets } from "src/core/queries/usePrintTickets";
import { TicketsModalComponent } from "src/components/atoms/TicketsModalComponent";
import { useGetTickets } from "src/core/queries/useGetTickets";

interface ConfirmationProps {
  updateCheckOutState: (newTabsState: Tab[], newPanelsState: CheckOutStep[]) => void;
  step: CheckOutStep;
  panels: CheckOutStep[];
  style?: any;
  tabs: Tab[];
  setTimer: (value: boolean) => void;
}

export const Confirmation: React.FC<ConfirmationProps> = ({
  updateCheckOutState,
  step,
  panels,
  style,
  tabs,
  setTimer,
}) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // info for bill
  const { formValues, setFormValues } = useFormValuesContext();
  const navigate = useNavigate();
  const originalDateString = formValues.date;
  const parsedDate = new Date(originalDateString);
  const formattedDate = format(parsedDate, "dd. M. yyyy");
  const selectedTime = formValues.time;
  const totalTickets = localStorage.getItem("totalTickets");
  const totalPrice = localStorage.getItem("totalPrice");

  const baby = localStorage.getItem("count1");
  const child = localStorage.getItem("count2");
  const adult = localStorage.getItem("count3");
  const senior = localStorage.getItem("count4");
  const family = localStorage.getItem("count5");
  const ztp = localStorage.getItem("count6");
  const free = localStorage.getItem("count7");

  const babyInfo = baby && parseInt(baby) > 0 ? `${baby} x ${t("children")}` : 0;
  const childInfo = child && parseInt(child) > 0 ? `${child} x ${t("children")}` : 0;
  const adultInfo = adult && parseInt(adult) > 0 ? `${adult} x ${t("adult")}` : 0;
  const seniorInfo = senior && parseInt(senior) > 0 ? `${senior} x ${t("senior")}` : 0;
  const familyInfo = family && parseInt(family) > 0 ? `${family} x ${t("family")}` : 0;
  const ztpInfo = ztp && parseInt(ztp) > 0 ? `${ztp} x ${t("ztp")}` : 0;
  const freeInfo = free && parseInt(free) > 0 ? `${free} x ${t("free")}` : 0;

  const navigateToTicketType = () => {
    navigate(TICKET_TYPE_ROUTE);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  // Post data
  const onSubmit = () => {
    setFormValues(clearFormValues);
    const savedName = localStorage.getItem("name");
    const savedEmail = localStorage.getItem("email");

    if (savedName !== null) {
      localStorage.setItem("name", savedName);
    }
    if (savedEmail !== null) {
      localStorage.setItem("email", savedEmail);
    }
    navigateToTicketType();
    // localStorage.clear();
    localStorage.removeItem("count1");
    localStorage.removeItem("count2");
    localStorage.removeItem("count3");
    localStorage.removeItem("count4");
    localStorage.removeItem("count5");
    localStorage.removeItem("count6");
    localStorage.removeItem("time");
    localStorage.removeItem("free_capacity");
    localStorage.removeItem("totalPrice");
    localStorage.removeItem("totalTickets");
    localStorage.removeItem("time_slot_id");
    localStorage.removeItem("loglevel");
    localStorage.removeItem("selectedSlotId");
    localStorage.removeItem("reservation");
    localStorage.removeItem("customer_paid");
    localStorage.removeItem("backupTotalPrice");
    localStorage.removeItem("discount");
  };

  const reservation = formValues.reservation || "";
  const { data } = usePrintTickets({ reservationId: reservation });

  const onPrint = async () => {
    setIsLoading(true);
    setDisabled(false);

    if (data?.pdfUrl) {
      try {
        window.open(data.pdfUrl, "_blank");
      } catch (error) {
        console.error("Failed to open PDF:", error);
      }
    } else {
      console.error("URL PDF ERROR or Reservation ID ERROR");
    }

    setIsLoading(false);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 800);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  useEffect(() => {
    if (step.isEnabled) {
      setTimer(false);
    }
  }, [setTimer, step.isEnabled]);

  const ticketsChose = formValues.order_type;
  const { data: ticketTypes } = useGetTickets(isMobile);
  const babyPrice = ticketTypes?.find((ticket) => ticket.name === "baby")?.price;
  const childPrice = ticketTypes?.find((ticket) => ticket.name === "child")?.price;
  const adultPrice = ticketTypes?.find((ticket) => ticket.name === "adult")?.price;
  const seniorPrice = ticketTypes?.find((ticket) => ticket.name === "senior")?.price;
  const familyPrice = ticketTypes?.find((ticket) => ticket.name === "family")?.price;
  const ztpPrice = ticketTypes?.find((ticket) => ticket.name === "ztp")?.price;
  const freePrice = ticketTypes?.find((ticket) => ticket.name === "free")?.price;

  const babyTotalPrice = Number(babyPrice) * Number(baby);
  const childTotalPrice = Number(childPrice) * Number(child);
  const adultTotalPrice = Number(adultPrice) * Number(adult);
  const seniorTotalPrice = Number(seniorPrice) * Number(senior);
  const familyTotalPrice = Number(familyPrice) * Number(family);
  const ztpTotalPrice = Number(ztpPrice) * Number(ztp);
  const freeTotalPrice = Number(freePrice) * Number(free);

  return (
    <Box>
      {step.hidden ? null : (
        <Box className={styless.container}>
          <Box className={styless.box}>
            <Box className={styless.title}>
              <img src={check} />
              {t("confirmation_title")}
            </Box>
            <Box className={styless.contentBox}>
              <Box className={styless.boxOne}>
                <Box className={styless.notesBoxLogo}>
                  <img src={logo} style={{ width: "85px", height: "34px" }} />
                </Box>

                <Box className={styless.notesBoxMain}>
                  <Box className={styless.notesBox}>
                    <span className={styless.notes}>{t("tickets2")}</span>
                    <span className={styless.notes} style={{ fontWeight: "600" }}>
                      {ticketsChose === "multi" ? t("group1") : t("individual")}
                    </span>
                  </Box>
                  <Box className={styless.notesBox}>
                    <span className={styless.selectedTime}>{t("amount_of_tickets")}</span>
                    <span className={styless.selectedTimeRed}>{totalTickets}</span>
                  </Box>
                  <Box style={{ width: "100%" }}>
                    <Box className={styless.price_blockMain}>
                      {!!babyInfo && (
                        <span className={styless.price_block}>
                          <div>{babyInfo}</div>
                          <div
                            style={{ fontWeight: 600, color: "rgba(6, 39, 98, 1)" }}
                          >{`${babyTotalPrice} Kč`}</div>
                        </span>
                      )}
                      {!!childInfo && (
                        <span className={styless.price_block}>
                          <div>{childInfo}</div>
                          <div
                            style={{ fontWeight: 600, color: "rgba(6, 39, 98, 1)" }}
                          >{`${childTotalPrice} Kč`}</div>
                        </span>
                      )}
                      {!!adultInfo && (
                        <span className={styless.price_block}>
                          <div>{adultInfo}</div>{" "}
                          <div
                            style={{ fontWeight: 600, color: "rgba(6, 39, 98, 1)" }}
                          >{`${adultTotalPrice} Kč`}</div>
                        </span>
                      )}
                      {!!seniorInfo && (
                        <span className={styless.price_block}>
                          <div>{seniorInfo}</div>{" "}
                          <div
                            style={{ fontWeight: 600, color: "rgba(6, 39, 98, 1)" }}
                          >{`${seniorTotalPrice} Kč`}</div>
                        </span>
                      )}
                      {!!familyInfo && (
                        <span className={styless.price_block}>
                          <div>{familyInfo}</div>{" "}
                          <div
                            style={{ fontWeight: 600, color: "rgba(6, 39, 98, 1)" }}
                          >{`${familyTotalPrice} Kč`}</div>
                        </span>
                      )}
                      {!!ztpInfo && (
                        <span className={styless.price_block}>
                          <div>{ztpInfo}</div>{" "}
                          <div
                            style={{ fontWeight: 600, color: "rgba(6, 39, 98, 1)" }}
                          >{`${ztpTotalPrice} Kč`}</div>
                        </span>
                      )}
                      {!!freeInfo && (
                        <span className={styless.price_block}>
                          <div>{freeInfo}</div>{" "}
                          <div
                            style={{ fontWeight: 600, color: "rgba(6, 39, 98, 1)" }}
                          >{`${freeTotalPrice} Kč`}</div>
                        </span>
                      )}
                      {/* show discount if any applied */}
                      {localStorage.getItem("discount") && (
                        <span className={styless.price_block}>
                          <div>
                            {t("discount")}{" "}
                            {localStorage.getItem("discount") === "BRNOPASS"
                              ? "Brnopass30"
                              : "Školy10"}
                          </div>
                          <div style={{ fontWeight: 600, color: "rgba(6, 39, 98, 1)" }}>{`- ${
                            Number(localStorage.getItem("backupTotalPrice")) -
                            Number(localStorage.getItem("totalPrice"))
                          } Kč`}</div>
                        </span>
                      )}
                    </Box>
                  </Box>
                  <div className={styless.lineHoriz}></div>
                  <Box className={styless.notesBox}>
                    <span className={styless.notes}>{t("payment_overall")}</span>
                    <span className={styless.notes} style={{ fontWeight: "600", color: "#E31340" }}>
                      {`${totalPrice} Kč`}
                    </span>
                  </Box>
                </Box>
                <Box className={styless.line} />
                <Box className={styless.selectedBlock}>
                  <Box className={styless.selectedBlockInner}>
                    <span className={styless.selectedTime}>{t("final_time")}</span>
                    <span className={styless.selectedTime}>{t("final_date")}</span>
                  </Box>
                  <Box className={styless.selectedBlockInner}>
                    <span className={styless.selectedTimeRed}>{selectedTime}</span>
                    <span className={styless.selectedTimeRed}>{formattedDate}</span>
                  </Box>
                  <Box className={styless.notesBoxLogo2}>
                    <img src={logo} style={{ width: "120px" }} />
                  </Box>
                </Box>
              </Box>

              {/*inserted*/}
              <Box
                className={`${styless.buttonToPrint} ${
                  isLoading ? styless.buttonToPrintDisabled : ""
                }`}
                style={{ textDecoration: "none" }}
                onClick={onPrint}
              >
                {isLoading ? (
                  t("loading")
                ) : (
                  <>
                    {t("print")} <img src={print} alt="Print icon" />
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Box className={styless.buttonBox}>
            <Box
              className={`${styless.buttonToFinish} ${
                disabled && !isMobile ? styless.buttonToFinishDisabled : ""
              }`}
              style={{ textDecoration: "none" }}
              onClick={onSubmit}
            >
              {t("finish")}
              {disabled ? <img src={checkMark2} /> : <img src={checkMark} />}
            </Box>
          </Box>
          {/* <TicketsModalComponent
            handleClose={() => setOpenModal(false)}
            open={openModal}
            disabled={() => setDisabled(true)}
            // pdfUrl={data?.pdfUrl}
          /> */}
        </Box>
      )}
    </Box>
  );
};
