import React, { forwardRef, useEffect, useState } from "react";
import AmountOfPeopleDesktop from "src/components/molecules/AmountOfPeople";
import { DateAndTime } from "src/components/molecules/DateAndTime";
import { Panel } from "src/components/molecules/Panel";
import { RecapPayment } from "src/components/molecules/RecapPayment";
import { CheckOutStep, Tab } from "src/core/types";
import style_css from "./styles.module.css";

import colorCalendar from "../../../assets/kalendar_cerveny.svg";
import blueCalendar from "../../../assets/calendar_bluee.svg";

import colorPeople from "../../../assets/ticket_color.svg";
import grayPeople from "../../../assets/ticket_gray.svg";
import bluePeople from "../../../assets/ticket_blue.svg";

import colorPayment from "../../../assets/payment_color.svg";
import grayPayment from "../../../assets/payment_gray.svg";
import bluePayment from "../../../assets/payment_blue.svg";

import grayDone from "../../../assets/done_gray.svg";
import colorDone from "../../../assets/done_color.svg";

import { useTranslation } from "react-i18next";
import { style } from "./style";
import { Box } from "@mui/material";
import { Confirmation } from "src/components/molecules/Confirmation";
import AmountOfPeople from "src/components/molecules/AmountOfPeople";

export interface ICheckOutState {
  tabs?: Tab[];
  panels?: CheckOutStep[];
}

interface ICheckOutProps {
  setTimer: (value: boolean) => void;
}

const CheckOut = forwardRef<HTMLDivElement, ICheckOutProps>(({ setTimer }, ref) => {
  const [activeTab, setActiveTab] = useState<string>("data_time_chosen");
  const handleStepChange = (newActiveTab: string) => {
    setActiveTab(newActiveTab);
  };
  const { t } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 800);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const [checkOutState, setCheckOutState] = useState<ICheckOutState>({
    tabs: [
      {
        title: t("data_time_chosen"),
        icon: blueCalendar,
        activeIcon: colorCalendar,
        doneIcon: blueCalendar,
        isTabActive: true,
        isTabDone: false,
        key: "1",
      },
      {
        title: t("amount_of_people"),
        icon: grayPeople,
        activeIcon: colorPeople,
        doneIcon: bluePeople,
        isTabActive: false,
        isTabDone: false,
        key: "2",
      },
      {
        title: t("recap_and_payment"),
        icon: grayPayment,
        activeIcon: colorPayment,
        doneIcon: bluePayment,
        isTabActive: false,
        isTabDone: false,
        key: "3",
      },
      {
        title: t("confirmation"),
        icon: grayDone,
        activeIcon: colorDone,
        doneIcon: grayDone,
        isTabActive: false,
        isTabDone: false,
        key: "4",
      },
    ],
    panels: [
      {
        description: "Panel",
        isEnabled: false,
        key: "0",
        component: Panel,
        isStepFinished: false,
        isPen: false,
      },
      {
        description: "Výběr data a času",
        isEnabled: true,
        key: "1",
        component: DateAndTime,
        isStepFinished: false,
        isPen: false,
      },
      {
        description: "Kolik lidí přijde?",
        isEnabled: false,
        key: "2",
        component: AmountOfPeople,
        isStepFinished: false,
        isPen: false,
      },
      {
        description: "Rekapitulace a platba",
        isEnabled: false,
        key: "3",
        component: RecapPayment,
        isStepFinished: false,
        isPen: false,
      },
      {
        description: "Potvrzení",
        isEnabled: false,
        key: "4",
        component: Confirmation,
        isStepFinished: false,
        isPen: false,
        hidden: true,
      },
    ],
  });

  const updateCheckOutState = (newTabsState: Tab[], newPanelsState: CheckOutStep[]) => {
    setCheckOutState((prevState) => {
      return {
        ...prevState,
        tabs: newTabsState,
        panels: newPanelsState,
      };
    });
  };
  return (
    <Box className={style_css.box}>
      {checkOutState?.panels?.map((step) => {
        const Component = step.component;
        return (
          <Component
            setTimer={setTimer}
            style={style.component}
            key={step.key}
            step={step}
            tabs={checkOutState.tabs}
            activeTab={activeTab}
            panels={checkOutState.panels}
            updateCheckOutState={updateCheckOutState}
            checkOutState={checkOutState}
          />
        );
      })}
    </Box>
  );
});
CheckOut.displayName = "CheckOut";
export default CheckOut;
