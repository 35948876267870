import { Box, Button, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import colorPeople from "../../../assets/ticket_color.svg";
import minus from "../../../assets/minus_gray.svg";
import plus from "../../../assets/plus_color.svg";
import { styles } from "./styles";
import styless from "./styles.module.css";
import arrow from "../../../assets/arrow_right.svg";
import dotIcon from "../../../assets/dot-single.svg";
import arrowDown from "../../../assets/arrow_down.svg";
import logo from "../../../assets/logo_jakub_pos.svg";
import line2 from "../../../assets/line2.png";
import GreyPeople from "../../../assets/pocet_lidi.svg";
import { UsePostPreReservation } from "src/core/queries/usePostPreReservation";
import { useFormValuesContext } from "src/core/context";
import { useGetTickets } from "src/core/queries/useGetTickets";
import { CheckOutStep, PreReservation, Tab } from "src/core/types";
import { useGetCapacity } from "src/core/queries/useGetCapacity";
import { format } from "date-fns";
import tickets from "components/icons/Tickets";

const initialCountersAmounts = {
  count1: Number(localStorage.getItem("count1")) || 0,
  count2: Number(localStorage.getItem("count2")) || 0,
  count3: Number(localStorage.getItem("count3")) || 0,
  count4: Number(localStorage.getItem("count4")) || 0,
  count5: Number(localStorage.getItem("count5")) || 0,
  count6: Number(localStorage.getItem("count6")) || 0,
  count7: Number(localStorage.getItem("count7")) || 0,
};

interface TicketNameToTypeIdMapper {
  [key: string]: number;
}

const counterNameToTicketNameMapper: { [key in keyof StateMobile]: string } = {
  count1: "baby",
  count2: "child",
  count3: "adult",
  count4: "senior",
  count5: "family",
  count6: "ztp",
  count7: "free",
};

const ticketNameToTypeIdMapper: TicketNameToTypeIdMapper = {
  baby: 1,
  child: 2,
  adult: 3,
  senior: 4,
  family: 5,
  ztp: 6,
  free: 7,
};

interface AmountOfPeopleProps {
  updateCheckOutState: (newTabsState: Tab[], newPanelsState: CheckOutStep[]) => void;
  step: CheckOutStep;
  panels: CheckOutStep[];
  style: any;
  tabs: Tab[];
}

const AmountOfPeopleMobile: React.FC<AmountOfPeopleProps> = ({
  updateCheckOutState,
  step,
  panels,
  style,
  tabs,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const [counters, setCounters] = useState(initialCountersAmounts);
  const [capacityChanging, setIsCapacityChanging] = useState<boolean>(false);

  const [errorBabiesWithoutAdults, setErrorBabiesWithoutAdults] = useState<boolean>(false);
  const [errorChildrenWithoutAdults, setErrorChildrenWithoutAdults] = useState<boolean>(false);

  const sectionRef = useRef<HTMLElement | null>(null);

  const { t } = useTranslation();
  const { formValues, setFormValues } = useFormValuesContext();
  const {
    data: capacityData,
    error: capacityError,
    isLoading: capacityIsLoading,
    refetch: refetchCapacity,
  } = useGetCapacity({
    pos: "kostnice",
    reservationId: localStorage.getItem("reservation") || formValues.reservation || "",
  });
  const { mutate: postPreReservation } = UsePostPreReservation();
  const { data: ticketTypes, isLoading: ticketTypesLoading } = useGetTickets(isMobile);

  const prices = {
    baby: ticketTypes?.find((ticket) => ticket.name === "baby")?.price,
    child: ticketTypes?.find((ticket) => ticket.name === "child")?.price,
    adult: ticketTypes?.find((ticket) => ticket.name === "adult")?.price,
    senior: ticketTypes?.find((ticket) => ticket.name === "senior")?.price,
    family: ticketTypes?.find((ticket) => ticket.name === "family")?.price,
    ztp: ticketTypes?.find((ticket) => ticket.name === "ztp")?.price,
    free: ticketTypes?.find((ticket) => ticket.name === "free")?.price,
  };

  const totalTicketsNumber = Object.values(counters).reduce(
    (totalValue, currentValue) => totalValue + currentValue,
    0,
  );

  const totalTicketsPrice = Object.keys(counters).reduce((acc, key) => {
    const count = counters[key as keyof StateMobile];
    const ticketName: string = counterNameToTicketNameMapper[key as keyof StateMobile];
    const pricePerTicket = Number(
      ticketTypes?.find((ticketType) => ticketType.name === ticketName)?.price ?? 0,
    );
    return acc + count * pricePerTicket;
  }, 0);

  const originalDateString = formValues.date;
  const parsedDate = new Date(originalDateString);
  const formattedDate = format(parsedDate, "dd. M. yyyy");
  const selectedTime = formValues.time;
  const isAnyTicketSelected = totalTicketsNumber > 0;
  const ticketsChose = formValues.order_type;
  const isAdultOrFamilySelected =
    counters.count3 > 0 ||
    counters.count4 > 0 ||
    counters.count5 > 0 ||
    counters.count6 > 0 ||
    counters.count7 > 0;
  const isOperationDisabled = capacityIsLoading || capacityChanging;
  const currentTimeSlot = formValues.time_slot_id;

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 800);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  useEffect(() => {
    // initial data for all counters in localStorage (between steps)
    localStorage.setItem("count1", "0");
    localStorage.setItem("count2", "0");
    localStorage.setItem("count3", "0");
    localStorage.setItem("count4", "0");
    localStorage.setItem("count5", "0");
    localStorage.setItem("count6", "0");
    localStorage.setItem("count7", "0");

    // initial data for all counters as state (for counting other values like totalTicketsNumber or totalTicketsPrice)
    setCounters({
      count1: 0,
      count2: 0,
      count3: 0,
      count4: 0,
      count5: 0,
      count6: 0,
      count7: 0,
    });
  }, []);

  const preparePreReservationData = useCallback(
    (state: any) => {
      let totalAmountTickets = 0;
      const ticketsObject = Object.keys(state).reduce((acc, key) => {
        const count = state[key as keyof StateMobile];
        if (count > 0) {
          totalAmountTickets += count;
          const ticketName = counterNameToTicketNameMapper[key as keyof StateMobile];
          const typeId = ticketNameToTypeIdMapper[ticketName];
          acc.push({ type: typeId, amount: count });
        }
        return acc;
      }, [] as { type: number; amount: number }[]);

      return {
        reservation: String(localStorage.getItem("reservation")),
        tickets: ticketsObject,
        token: String(localStorage.getItem("token")),
        pos: isMobile ? "kustod" : "kostnice",
      };
    },
    [isMobile],
  );

  useEffect(() => {
    setIsCapacityChanging(true);
    postPreReservation(preparePreReservationData(counters), {
      onSuccess: () => {
        refetchCapacity();
        localStorage.setItem("totalTickets", totalTicketsNumber.toString());
        localStorage.setItem("totalPrice", totalTicketsPrice.toString());
        setIsCapacityChanging(false);
      },
    });
  }, [
    counters,
    postPreReservation,
    preparePreReservationData,
    refetchCapacity,
    totalTicketsNumber,
    totalTicketsPrice,
  ]);

  useEffect(() => {
    sectionRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, [step.isEnabled]);

  useEffect(() => {
    refetchCapacity();
  }, [currentTimeSlot, refetchCapacity]);

  const handleDecrementTickets = (counter: keyof StateMobile) => {
    if (totalTicketsNumber <= 0) return;

    if (
      (counter === "count3" ||
        counter === "count4" ||
        counter === "count5" ||
        counter === "count6" ||
        counter === "count7") &&
      counters.count3 + counters.count4 + counters.count5 + counters.count6 + counters.count7 <= 1
    ) {
      if (counters.count1) {
        setErrorBabiesWithoutAdults(true);
        return;
      } else {
        setErrorBabiesWithoutAdults(false);
      }
      if (counters.count2) {
        setErrorChildrenWithoutAdults(true);
        return;
      } else {
        setErrorChildrenWithoutAdults(false);
      }
    }

    if (counters.count1 <= 1) setErrorBabiesWithoutAdults(false);
    if (counters.count2 <= 1) setErrorChildrenWithoutAdults(false);

    setCounters((prevState: any) => {
      if (prevState[counter] <= 0) return prevState;
      const newState = { ...prevState, [counter]: prevState[counter] - 1 };
      localStorage.setItem(counter, String(newState[counter]));
      setFormValues((prev) => ({
        ...prev,
        [counter]: newState[counter],
      }));
      return newState;
    });
  };

  const handleIncrementTickets = (counter: keyof StateMobile) => {
    const availableCapacity = capacityData?.capacity || 0;

    // allow on mobile devices to bypass the capacity limit
    if (!isMobile && availableCapacity <= 0) return;

    // rules for baby (count1) and child (count2) tickets
    if (!isAdultOrFamilySelected) {
      switch (counter) {
        case "count1":
          setErrorBabiesWithoutAdults(true);
          return;
        case "count2":
          setErrorChildrenWithoutAdults(true);
          return;
        case "count3":
        case "count4":
        case "count5":
        case "count6":
        case "count7":
          setErrorBabiesWithoutAdults(false);
          setErrorChildrenWithoutAdults(false);
          break;
        default:
          break;
      }
    }

    setCounters((prevState: any) => {
      const newState = { ...prevState, [counter]: prevState[counter] + 1 };
      localStorage.setItem(counter, String(newState[counter]));
      setFormValues((prev) => ({
        ...prev,
        [counter]: newState[counter],
      }));
      return newState;
    });
  };

  const onSubmit = () => {
    const data = preparePreReservationData(counters);
    postPreReservation(data, {
      onSuccess: () => {
        setFormValues({
          ...formValues,
          tickets: data.tickets,
          totalPrice: totalTicketsPrice,
        });

        const newTabsState = tabs.map((tab: Tab) => {
          if (tab.key === "3") {
            return { ...tab, isTabActive: true };
          } else if (tab.key === "2") {
            return { ...tab, isTabDone: true, isTabActive: false };
          } else {
            return { ...tab, isTabActive: false };
          }
        });

        const newState = panels.map((panel: CheckOutStep) => {
          if (panel.key === "1") {
            return { ...panel, isEnabled: false, isPen: true };
          }
          if (panel.key === "2") {
            return { ...panel, isStepFinished: true, isEnabled: false, isPen: true };
          }
          if (panel.key === "3") {
            return { ...panel, isEnabled: true };
          }
          return { ...panel, isEnabled: false, isPen: false };
        });
        updateCheckOutState(newTabsState, newState);
      },
    });
  };

  const penClick = () => {
    const newState = panels.map((panel: CheckOutStep) => {
      if (panel.key === "1") {
        return { ...panel };
      }
      if (panel.key === "2") {
        return { ...panel, isEnabled: !panel.isEnabled };
      }
      if (panel.key === "3") {
        return { ...panel };
      }
      if (panel.key === "4") {
        return { ...panel };
      }
      return { ...panel, isEnabled: false };
    });
    updateCheckOutState(tabs, newState);
  };

  if (capacityIsLoading || ticketTypesLoading) {
    return null;
  }

  if (capacityError) {
    return null;
  }

  return (
    <Box ref={sectionRef}>
      {step.hidden ? null : (
        <Box style={style}>
          {step.isEnabled ? (
            <>
              <Box className={styless.container}>
                <Box className={styless.contentWrapper}>
                  <Box className={styless.title_row}>
                    <Box style={styles.title}>
                      <img src={colorPeople} style={{ marginRight: "10px" }} />
                      {t("title_amount_of_people")}
                    </Box>
                    <Box className={styless.subtitle}>
                      <span>{t("noteOne")}</span>
                      <span className={styless.capacity}>{capacityData?.capacity}</span>
                      <span className={styless.capacity}> {t("noteTwo")}</span>
                    </Box>
                  </Box>
                  <Box className={styless.mainBox}>
                    <div className={isMobile ? styless.innerBoxMobile : styless.innerBox}>
                      {ticketTypes?.find((ticket) => ticket.name === "adult") && (
                        <Box className={styless.boxOuter}>
                          <Box className={styless.box}>
                            <Box className={styless.subTitleBox}>
                              <span style={styles.sudTitle}> {t("adult_entrance")} </span>
                              <span style={styles.sudTitle2}>{t("adult_condition")}</span>
                            </Box>
                            <Box className={styless.priceBox}>
                              <span className={styless.price}>{prices.adult} Kč</span>
                            </Box>
                            <Box className={styless.buttonsWrapper}>
                              <Box className={styless.buttons}>
                                <Button
                                  sx={styles.buttonDecrement}
                                  onClick={() => handleDecrementTickets("count3")}
                                  disabled={isOperationDisabled}
                                >
                                  <img src={minus} />
                                </Button>
                                <Button sx={styles.buttonCount}>{counters.count3}</Button>
                                <Button
                                  sx={styles.buttonIncrement}
                                  onClick={() => handleIncrementTickets("count3")}
                                  disabled={isOperationDisabled}
                                >
                                  <img src={plus} />
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                          {/*{errorIncrementLimitAdult && (*/}
                          {/*  <Box>*/}
                          {/*    <span style={styles.notesRed}>*/}
                          {/*      {`${t("tickets_limit")} ${capacityData?.capacity} ${t("places")}`}*/}
                          {/*    </span>*/}
                          {/*  </Box>*/}
                          {/*)}*/}
                        </Box>
                      )}
                      {ticketTypes?.find((ticket) => ticket.name === "family") && (
                        <Box className={styless.boxOuter}>
                          <Box className={styless.box}>
                            <Box className={styless.subTitleBox}>
                              {isMobile ? (
                                <span style={styles.sudTitle}>{t("family_mobile")}</span>
                              ) : (
                                <span style={styles.sudTitle}>{t("family_entrance")}</span>
                              )}
                              {isMobile ? (
                                <span style={styles.sudTitle2}>{t("family_condition_mobile")}</span>
                              ) : (
                                <span style={styles.sudTitle2}>{t("family_condition")}</span>
                              )}
                            </Box>
                            <Box className={styless.priceBox}>
                              <span className={styless.price}>{prices.family} Kč</span>
                            </Box>
                            <Box className={styless.buttonsWrapper}>
                              <Box className={styless.buttons}>
                                <Button
                                  sx={styles.buttonDecrement}
                                  onClick={() => handleDecrementTickets("count5")}
                                  disabled={isOperationDisabled}
                                >
                                  <img src={minus} />
                                </Button>
                                <Button sx={styles.buttonCount}>{counters.count5}</Button>
                                <Button
                                  sx={styles.buttonIncrement}
                                  onClick={() => handleIncrementTickets("count5")}
                                  disabled={isOperationDisabled}
                                >
                                  <img src={plus} />
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                          {!isMobile && (
                            <Box className={styless.notesGreyBox}>
                              <span className={styless.noteForLayout}>element_placeholder</span>
                            </Box>
                          )}
                          {/*{errorIncrementFamilyEntrance && (*/}
                          {/*  <Box>*/}
                          {/*    <span style={styles.notesRed}> {t("Lorem")}</span>*/}
                          {/*  </Box>*/}
                          {/*)}*/}
                        </Box>
                      )}
                      {ticketTypes?.find((ticket) => ticket?.name === "baby") && (
                        <Box className={styless.boxOuter}>
                          <Box className={styless.box}>
                            <Box className={styless.subTitleBox}>
                              <span style={styles.sudTitle}> {t("children")}</span>
                              <span style={styles.sudTitle2}> {t("children_type1")}</span>
                            </Box>
                            <Box className={styless.priceBox}>
                              <span className={styless.price}>{prices.baby} Kč</span>
                            </Box>
                            <Box className={styless.buttonsWrapper}>
                              <Box className={styless.buttons}>
                                <Button
                                  sx={styles.buttonDecrement}
                                  onClick={() => handleDecrementTickets("count1")}
                                  disabled={isOperationDisabled}
                                >
                                  <img src={minus} />
                                </Button>
                                <Button sx={styles.buttonCount}>{counters.count1}</Button>
                                <Button
                                  sx={styles.buttonIncrement}
                                  onClick={() => handleIncrementTickets("count1")}
                                  disabled={isOperationDisabled}
                                >
                                  <img src={plus} />
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                          {errorBabiesWithoutAdults && (
                            <Box>
                              <span className={styless.notesRed}>{t("child_with_adult")}</span>
                            </Box>
                          )}
                        </Box>
                      )}
                    </div>
                    <div className={isMobile ? styless.innerBoxMobile : styless.innerBox}>
                      {ticketTypes?.find((ticket) => ticket?.name === "child") && (
                        <Box className={styless.boxOuter}>
                          <Box className={styless.box}>
                            <Box className={styless.subTitleBox}>
                              <span style={styles.sudTitle}> {t("children")} </span>
                              <span style={styles.sudTitle2}>{t("children_type2")} </span>
                            </Box>
                            <Box className={styless.priceBox}>
                              <span className={styless.price}>{prices.child} Kč</span>
                            </Box>
                            <Box className={styless.buttonsWrapper}>
                              <Box className={styless.buttons}>
                                <Button
                                  sx={styles.buttonDecrement}
                                  onClick={() => handleDecrementTickets("count2")}
                                  disabled={isOperationDisabled}
                                >
                                  <img src={minus} />
                                </Button>
                                <Button sx={styles.buttonCount}>{counters.count2}</Button>
                                <Button
                                  sx={styles.buttonIncrement}
                                  onClick={() => handleIncrementTickets("count2")}
                                  disabled={isOperationDisabled}
                                >
                                  <img src={plus} />
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            {errorChildrenWithoutAdults && (
                              <span className={styless.notesRed}>{t("child_with_adult")}</span>
                            )}
                          </Box>
                        </Box>
                      )}
                      {ticketTypes?.find((ticket) => ticket?.name === "senior") && (
                        <Box className={styless.boxOuter}>
                          <Box className={styless.box}>
                            <Box className={styless.subTitleBox}>
                              {isMobile ? (
                                <span style={styles.sudTitle}>{t("senior_mobile")}</span>
                              ) : (
                                <span style={styles.sudTitle}>{t("senior_entrance")}</span>
                              )}
                              <span style={styles.sudTitle2}>{t("senior_condition")}</span>
                            </Box>
                            <Box className={styless.priceBox}>
                              <span className={styless.price}>{prices.senior} Kč</span>
                            </Box>
                            <Box className={styless.buttonsWrapper}>
                              <Box className={styless.buttons}>
                                <Button
                                  sx={styles.buttonDecrement}
                                  onClick={() => handleDecrementTickets("count4")}
                                  disabled={isOperationDisabled}
                                >
                                  <img src={minus} />
                                </Button>
                                <Button sx={styles.buttonCount}>{counters.count4}</Button>
                                <Button
                                  sx={styles.buttonIncrement}
                                  onClick={() => handleIncrementTickets("count4")}
                                  disabled={isOperationDisabled}
                                >
                                  <img src={plus} />
                                </Button>
                              </Box>
                            </Box>
                          </Box>

                          <Box className={styless.notesGreyBox}>
                            <span className={styless.notesGrey}>{t("senior_notes")}</span>
                          </Box>
                        </Box>
                      )}
                      {ticketTypes?.find((ticket) => ticket?.name === "ztp") && (
                        <Box className={styless.boxOuter}>
                          <Box className={styless.box}>
                            <Box className={styless.subTitleBox}>
                              <span style={styles.sudTitle}> {t("ztp")} </span>
                              <span style={styles.sudTitle2}>{t("senior_entrance")} </span>
                            </Box>
                            <Box className={styless.priceBox}>
                              <span className={styless.price}>{prices.ztp} Kč</span>
                            </Box>
                            <Box className={styless.buttonsWrapper}>
                              <Box className={styless.buttons}>
                                <Button
                                  sx={styles.buttonDecrement}
                                  onClick={() => handleDecrementTickets("count6")}
                                  disabled={isOperationDisabled}
                                >
                                  <img src={minus} />
                                </Button>
                                <Button sx={styles.buttonCount}>{counters.count6}</Button>
                                <Button
                                  sx={styles.buttonIncrement}
                                  onClick={() => handleIncrementTickets("count6")}
                                  disabled={isOperationDisabled}
                                >
                                  <img src={plus} />
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      )}
                      {ticketTypes?.find((ticket) => ticket?.name === "free") && (
                        <Box className={`${styless.boxOuter} ${styless.tablet_only}`}>
                          <Box className={styless.box}>
                            <Box className={styless.subTitleBox}>
                              <span style={styles.sudTitle}>{t("enter_for_free")}</span>

                              <span style={styles.sudTitle2}>{t("free_notes")}</span>
                            </Box>
                            <Box className={styless.priceBox}>
                              <span className={styless.price}>{prices.free} Kč</span>
                            </Box>
                            <Box className={styless.buttonsWrapper}>
                              <Box className={styless.buttons}>
                                <Button
                                  sx={styles.buttonDecrement}
                                  onClick={() => handleDecrementTickets("count7")}
                                  disabled={isOperationDisabled}
                                >
                                  <img src={minus} />
                                </Button>
                                <Button sx={styles.buttonCount}>{counters.count7}</Button>
                                <Button
                                  sx={styles.buttonIncrement}
                                  onClick={() => handleIncrementTickets("count7")}
                                  disabled={isOperationDisabled}
                                >
                                  <img src={plus} />
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </div>
                    {isMobile ? (
                      <Box className={styless.mobile_label}>
                        <Box className={styless.mobile_label_icon_wrapper}>
                          <img src={logo} className={styless.mobile_label_icon} />
                        </Box>
                        <Box className={styless.mobile_label_tickets_wrapper}>
                          <Box className={styless.mobile_label_tickets_type}>
                            {t("tickets2")}
                            <span style={{ fontWeight: "700" }}>
                              {ticketsChose === "multi" ? t("group1") : t("individual")}
                            </span>
                          </Box>
                          <Box className={styless.mobile_label_tickets_amount}>
                            {t("amount_of_tickets")}
                            <span style={{ color: "#E31340", fontWeight: "700" }}>
                              {totalTicketsNumber}
                            </span>
                          </Box>
                        </Box>
                        <Box className={styless.mobile_label_notes_wrapper}>
                          <Box className={styless.mobile_label_notes_datetime}>
                            {t("data_time_chosen")}:
                            <span
                              style={{
                                color: "#E31340",
                                fontWeight: "700",
                              }}
                            >
                              <span style={{ display: "inline-block" }}>
                                {formattedDate}
                                &nbsp;
                              </span>
                              <span style={{ display: "inline-block" }}>{selectedTime}</span>
                            </span>
                          </Box>
                          <Box className={styless.mobile_label_notes_payment}>
                            {t("payment_overall")}
                            <span style={{ color: "#E31340", fontWeight: "700" }}>
                              {totalTicketsPrice} Kč
                            </span>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Box className={styless.desktop_label}>
                        <Box className={styless.ladelBox}>
                          <Box className={styless.rowLabel}>
                            <img src={logo} className={styless.rowLabelLogo} />
                            <Typography className={styless.rowLabelTitleText}>
                              {t("tickets3")}
                            </Typography>
                          </Box>
                          <Box className={styless.priceBlock}>
                            <Box className={styless.rowLabel}>
                              <span className={styless.rowLabelText}>{t("amount_of_tickets")}</span>
                              <span className={styless.rowLabelText}>{totalTicketsNumber}</span>
                            </Box>
                            <img className={styless.line} src={line2} />
                            <Box className={styless.rowLabel} style={{ fontWeight: "600" }}>
                              <span className={styless.rowLabelText}>{t("payment_overall")}</span>
                              <span className={styless.rowLabelText}>{totalTicketsPrice} Kč</span>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Box className={styless.buttonToGoSection}>
                    <Button
                      className={styless.buttonToGo}
                      disabled={!isAnyTicketSelected}
                      sx={{
                        ...styles.buttonToGo,
                        "&:hover": {
                          backgroundColor: "#E31340",
                          color: "white",
                        },
                        "&:disabled": {
                          backgroundColor: "#B9B9B9",
                          color: "white",
                        },
                      }}
                      style={{ textDecoration: "none", padding: "0 24px" }}
                      onClick={onSubmit}
                    >
                      <span style={{ marginRight: "5px", fontWeight: "400" }}>
                        {t("recap_and_payment")}
                      </span>
                      <img src={arrow} />
                    </Button>
                  </Box>
                </Box>
              </Box>
              {/* <Confirmation /> */}
            </>
          ) : (
            step.isStepFinished && (
              <Box className={styless.closedBox}>
                <div className={styless.rowBox}>
                  {step.isStepFinished && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <img src={GreyPeople} />
                      <Typography
                        sx={{
                          color: "#051C4766",
                          fontWeight: "600",
                        }}
                      >
                        {t("amount_of_tickets2")} {totalTicketsNumber}
                      </Typography>
                    </div>
                  )}

                  {!step.isStepFinished && (
                    <div
                      style={{
                        width: "250px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <img src={GreyPeople} />
                      <Box style={{ color: "#B9B9B9", fontWeight: "600" }}>
                        {t("amount_of_tickets2")}
                        {"  "}
                        {totalTicketsNumber}
                      </Box>
                    </div>
                  )}
                </div>
                {step.isPen && (
                  <span className={styless.pen} onClick={penClick}>
                    {t("change")}
                  </span>
                )}
              </Box>
            )
          )}
        </Box>
      )}
    </Box>
  );
};

AmountOfPeopleMobile.displayName = "AmountOfPeopleMobile";
export default AmountOfPeopleMobile;
