export const TICKETS_API_ROUTE = "ticket_types";

export const SLOTS_API_ROUTE = "slots_by_date"; // <date>

export const PRE_RESERVATION_API_ROUTE = "pre-reservation";

export const RESERVATION_API_ROUTE = "reservation";

export const DISCOUNT_API_ROUTE = "discount"; // <code>

export const MONTH_API_ROUTE = "month_days";

export const STATUS_API_ROUTE = "ticket_status";

export const STOP_RESERVATION_API_ROUTE = "remove_reservation";

export const CHECK_CAPACITY_API_ROUTE = "check_capacity";

export const LOGIN_API_ROUTE = "login";

export const USER_DATA_API_ROUTE = "get_user_data";

export const TOKEN_AUTH_API_ROUTE = "token_auth";

export const MANUAL_PAYMENT_API_ROUTE = "process_manual_payment";

export const TICKETS_GENERATE_API_ROUTE = "generate_mini"; // <id>
