import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LOGIN_ROUTE, PRE_RESERVATION_ROUTE } from "src/core/routing";
import style from "./styles.module.css";
import individual from "../../../assets/osoba_white.svg";
import caret from "../../../assets/caret_right.png";
import arrow from "../../../assets/arrow.svg";
import { styles } from "./style";

export const TicketTypeContent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToPrereservation = () => {
    navigate(PRE_RESERVATION_ROUTE);
  };

  const navigateToLogin = () => {
    navigate(LOGIN_ROUTE);
  };
  return (
    <Box className={style.container}>
      {/* <Box
        className={style.back}
        onClick={() => {
          navigateToLogin();
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }}
      >
        <img src={arrow} />
        <Typography sx={styles.back}>{t("back")}</Typography>
      </Box> */}

      <Typography
        textAlign={"center"}
        color={"#062762"}
        fontWeight={600}
        variant="h2"
        margin={"0 0 8vh 0"}
      >
        {t("choose_the_following_variant")}
      </Typography>
      <Button
        sx={{
          ...styles.button,
          textDecoration: "none !important",
          "&:hover": {
            backgroundColor: "#062762",
            color: "#FFFFFF !important",
            textDecoration: "none !important",
          },
        }}
        className={style.button}
        onClick={() => {
          navigateToPrereservation();
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }}
      >
        <img src={individual} className={style.icon} />
        <Box>
          <span className={style.buttonText}>{t("tickets_for_individual")}</span>
        </Box>
        <img
          className={style.caret}
          src={caret}
          style={{
            position: "absolute",
            top: "50%",
            left: "100%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </Button>
    </Box>
  );
};
